<template>
  <div class="domain-detail-history">
    <transition name="fade" mode="out-in">
      <base-table
        v-if="list.length"
        :list="sortedList"
        :columns="tableHead"
        :sort="sort"
        class="standart-text"
        @sort-change="onSortChange"
      >
        <base-table-row v-for="(item, i) in sortedList" :key="i" :item="item" :columns="tableHead">
          <template #desc>
            {{ item.desc }}
          </template>
          <template #date>
            {{ format(item.changedate) }}
          </template>
          <template #user>
            {{ item.user }}
          </template>
        </base-table-row>
      </base-table>
      <base-loader v-else />
    </transition>
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import { sortFuncsByOrder } from '@/utils/sortFunctions';
import { format } from 'date-fns';
import { DomainTariff } from '@/models/BillMgr/DomainTariff';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import { DnsHostTariff } from '@/models/BillMgr/DnsHostTariff';
import { DnsHost } from '@/models/DnsMgr/DnsHost';
export default {
  name: 'VpsHistory',
  components: { BaseTable, BaseTableRow },
  mixins: [billMgrToolActions],
  props: {
    tariff: {
      type: [DomainTariff, DnsHostTariff, DnsHost],
      required: true,
      validator: obj =>
        obj instanceof DomainTariff || obj instanceof DnsHostTariff || obj instanceof DnsHost,
    },
    moduleMain: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'desc',
          label: this.$t('desc'),
          style: {
            width: '33%',
            minWidth: '160px',
          },
        },
        {
          key: 'date',
          label: this.$t('date'),
          style: {
            width: '33%',
            minWidth: '135px',
          },
          sort: {
            prop: 'changedate',
            order: 'asc',
          },
        },
        {
          key: 'user',
          label: this.$t('user'),
          style: {
            width: '33%',
            minWidth: '132px',
          },
        },
      ],
      sort: {
        prop: 'changedate',
        order: 'desc',
      },
      list: [],
    };
  },
  computed: {
    current() {
      if (this.tariff instanceof DnsHost && this.tariff.billMgrDnsHost instanceof DnsHostTariff)
        return this.tariff.billMgrDnsHost;
      else return this.tariff;
    },
    tools() {
      const splitted = this.moduleMain.split('.');
      const path = splitted.reduce((acc, item) => {
        return acc[item];
      }, this.$store.state);
      return path.tools;
    },
    toolHistory() {
      if (!this.tools || !this.tools.history || !this.tools.history.isEnable(this.current))
        return null;
      return this.tools.history;
    },
    sortedList() {
      let list = [...this.list];
      const { prop, order } = this.sort;
      list.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
      return list;
    },
  },
  watch: {
    toolHistory(tool) {
      if (tool && tool.isEnable(this.current)) this.fetch();
    },
  },
  created() {
    if (this.toolHistory && this.toolHistory.isEnable(this.current)) this.fetch();
  },
  methods: {
    fetch(id = this.current.id) {
      const params = {
        elid: id,
        func: this.toolHistory.func,
      };
      this.fetchBillMgrAction(params).then(data => {
        this.list = [...data.elem];
      });
    },
    onSortChange(payload) {
      const { prop, order } = payload;
      if (this.sort.prop === prop) {
        this.sort.order = order === 'asc' ? 'desc' : 'asc';
      } else {
        this.sort = payload;
      }
      this.updateSort(this.sort);
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    format(date, tpl) {
      tpl = tpl || 'dd.MM.yyyy HH:mm:ss';
      return format(new Date(date), tpl);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "desc": "Что произошло",
    "date": "Когда",
    "user": "Инициатор"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.domain-detail-history {
  margin-top: 1.5rem;

  +breakpoint(sm-and-up) {
    margin-top: 2.5rem;
  }
}
</style>
